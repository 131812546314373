/**
 * Configuration for @theme-ui and gatsby-plugin-theme-ui.
 */

import '../assets/fonts/bwsurco/bwsurco.css';

const config = {
  breakpoints: ['959px', '960px'],
  sizes: {
    container: 768,
  },
  fonts: {
    body: 'BW Surco, sans-serif',
    heading: 'BW Surco, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [16, 20, 24, 32, 48, 64],
  lineHeights: {
    body: 5,
    heading: 1.125,
  },
  colors: {
    primary: '#4C8BFF',
    secondary: '#FE6469',
    text: '#191E28',
    background: '#F7FAFF',
  },
};

// Duplicate these so if we want we can use the actual color values and not the
// CSS variables that theme-ui converts these to.
config.colorsActual = config.colors;

export default config;
